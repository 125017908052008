import React, {useState} from 'react';
import { Input } from 'reactstrap';

export const InputPassword = ({
  type, placeholder, onChange, isLoading, required, value, id, autoComplete, name
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }
  return (
    <>
      <Input
        className='position-relative'
        name={name}
        disabled={isLoading}
        type={showPassword ? 'text' : type}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        value={value}
        id={id}
        autoComplete={autoComplete}
      />
      <div  role='button'  className='position-absolute end-0 pointer pr-2 pt-2'  onClick={togglePasswordVisibility}>
        {showPassword ? <i className="fa fa-eye-slash" style={{opacity: 0.5}}></i> : <i className="fa fa-eye " style={{opacity: 0.5}}></i>}
      </div>
    </>
  )
};
