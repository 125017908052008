import axios from 'axios';

const API_URL = process.env.REACT_APP_MY_VARIABLE_API_URL;

const allowedRoles = [3];
class AuthenticationService {
  constructor() {
    this.getAuthUser = this.getAuthUser.bind(this);
    this.getToken = this.getToken.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getAuthUser() {
    return localStorage.getItem('user');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  isAuthenticated() {
    return this.getAuthUser() !== null;
  }

  signIn(username, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}login/`, { email: username, password: password })

        .then((data) => {
          if (allowedRoles.includes(data.data.role)) {
            const token = data.data.token.replace(/^"(.*)"$/, '$1');
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(data.data));
            this.setUserCompanies(data.data)
              .then((r) => {
                resolve(data.data.user);
              })
              .catch((e) => {
                reject(e);
              });
          } else {
            reject('forbidden_user');
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setUserCompanies(data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_URL}users/${data.id}/companies`, {
          headers: {
            Authorization: 'Bearer ' + data.token,
          },
        })
        .then((data) => {
          localStorage.setItem('companies', JSON.stringify(data.data.results));
          localStorage.setItem('selectedCompany', JSON.stringify(data.data.results[0]));
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  signUp(signUpData) {
    const { firstName, lastName, password, typeUserCompany, email, cuit, companyName, isMining, isIndustry } = { ...signUpData };
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}front/users/`, {
          firstName,
          lastName,
          password,
          typeUserCompany,
          email,
          cuit,
          companyName,
          role: 3,
          isMining,
          isIndustry,
        })

        .then((data) => {
          console.log({ data });
          resolve(data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('companies');
    localStorage.removeItem('selectedCompany');
    window.location.href = '/login';
  }

  confirmMail(token) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${API_URL}users/confirm`,
          { validateEmail: 0 },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((data) => {
          if (allowedRoles.includes(data.data.role)) {
            const token = data.data.token.replace(/^"(.*)"$/, '$1');
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(data.data));
            this.setUserCompanies(data.data)
              .then((r) => {
                resolve(data.data.user);
              })
              .catch((e) => {
                reject(e);
              });
          } else {
            reject('forbidden_user');
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  resendValidationEmail(token) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${API_URL}users/resend-email`,
          { token: token },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  recoveryPassword(email) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}reset-password`, { email: email })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  getByRecoveryToken(token, params = {}) {
    const sendData = {
      validateEmail: 0,
      ...params,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`${API_URL}users/confirm`, sendData, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
